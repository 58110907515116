import axios from 'axios'

export class Transferecia {

  static async store(playload) {
    const anoSelecionado = parseInt(sessionStorage.getItem("anoSelecionado"));
    playload.ano = anoSelecionado;
    return axios.post('/transferencias', playload)
  }

  static async obtemTodos(flag_transferido, fullName) {
    const anoSelecionado = sessionStorage.getItem("anoSelecionado");
    return axios.get('/transferencias/' + flag_transferido + '/' + anoSelecionado + '?fullName='+fullName);
  }

  static async remover(id) {
    return axios.delete('/transferencias/' + id)
  }

  static async alterar(payload) {
    return axios.put("/transferencias/" + payload.id, payload);
  }


  static async addAlunoDeVoltaATurma(payload) {
    return axios.put("cancelar/transferencia/" + payload.id, payload);
  }
}
