<template>
  <div>
    <pm-DataTable
      :value="historicoDePresencas.info.turmasInfo"
      filterDisplay="menu"
      responsiveLayout="scroll"
      :rows="40"
    >
      <pm-Column
        field="escola"
        header="Escola"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{ data.escolaNome.nome }}</p>
        </template>
      </pm-Column>
  
      <pm-Column
        field="serie"
        header="Série"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{ data.serieNome.nome }}</p>
        </template>
      </pm-Column>
  
      <pm-Column
        field="turma"
        header="Turma"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{ data.turmaNome }}</p>
        </template>
      </pm-Column>
  
      <pm-Column
        field="total_presenca"
        header="Total De Presenças do aluno"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{ data.total_presencas + data.total_presencas_manual }}</p>
        </template>
      </pm-Column>
  
      <pm-Column
        field="presencas_lancadas"
        header="Total De Aulas Lançadas"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{ data.total_lancadas + data.total_lancadas_manual }}</p>
        </template>
      </pm-Column>
  
      <pm-Column
        field="percentual"
        header="Porcentagem(%)"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p
            v-if="
              data.total_lancadas !== 0 ||
                data.total_lancadas_manual !== 0
            "
          >
            {{
              Math.round(
                ((data.total_presencas + data.total_presencas_manual) /
                  (data.total_lancadas + data.total_lancadas_manual)) *
                  100
              )
            }}%
          </p>
        </template>
      </pm-Column>
    </pm-DataTable>
    <br /><br />
    <div style="float: right;">
      <p>
      <h6>Presenças Totais: {{ historicoDePresencas.info.total_presencas_geral }}</h6>
      <h6>Aulas Lançadas Totais {{ historicoDePresencas.info.total_lancadas_geral }}</h6>
      <h6
        v-if="
          historicoDePresencas.info.total_lancadas_geral !== 0 ||
            historicoDePresencas.info.total_lancadas_manual_geral !== 0
        "
      >
        Porcentagem Total: {{
          Math.round(
            ((historicoDePresencas.info.total_presencas_geral +
              historicoDePresencas.info.total_presencas_manual_geral) /
              (historicoDePresencas.info.total_lancadas_geral +
                historicoDePresencas.info.total_lancadas_manual_geral)) *
              100
          )
        }}%
      </h6>
      </p>
    </div>
  </div>
</template>
  
  <!-- <script>
  export default {
    name: 'HistorioDePresencas',
    props: {
      historicoDePresencas: {
        type: Object,
        required: true
      }
    }
  };
  </script> -->

<script>
    export default {
    name: "HistorioDePresencas",
    props: {
      
        historicoDePresencas: { type: Array, default: () => [] },

    },
    async beforeMount() {
    },
    };
</script>
  
  <style scoped>
  </style>
  